import { message } from 'antd';
import { Job, ShortLink } from 'apis/APISDK';
import { JOB_PAGE_LIMIT } from 'helpers/constants';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* CREATE_JOB(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const {
      success,
      message: resMessage,
      data,
      status,
    } = yield call(Job.createJob, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          lastCreatedJob: data,
        },
      });
      if (data?.id) {
        history.push({
          pathname: routes.JOB_SETUP(data?.id),
        });
      }
      yield put({ type: actions.INVOKE_LIST_JOB });
      message.success(resMessage);
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_JOB(payloads) {
  const { payload: reqPayload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      jobListStages: [],
      archiveStage: null,
    },
  });
  try {
    let payload = reqPayload;
    const { activeJobsStatus, currentPage } = yield select(
      (state) => state.jobs
    );
    if (!reqPayload) {
      payload = {
        payload: {
          status: activeJobsStatus,
          page: currentPage,
          limit: JOB_PAGE_LIMIT,
        },
      };
    }
    const { data, success, status } = yield call(Job.listJob, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          jobList: data.jobs.results,
          jobTotalResults: data.jobs.totalResults,
          jobTotalPages: data.jobs.totalPages,
          jobListCandidateCount: data.candidateCount,
          jobsStages: data.jobStages,
          loading: false,
        },
      });
    } else {
      yield handleApiError(status);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_JOB(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      data,
      status,
      message: resMessage,
    } = yield call(Job.getJob, {
      id: payload.id,
    });
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: data.job,
          currentCandidateCount: data.candidateCount,
          jobListStages: data.job.workflow.stages,
          archiveStage: data.job.workflow.stages.find(
            (stage) => stage.isArchiveStage
          ),
        },
      });
    } else {
      yield handleApiError(status);
      history.push(routes.DASHBOARD);
      if (resMessage === 'You are not part of the hiring team') {
        message.error(resMessage);
      } else {
        message.error('Failed to fetch job details, please try again.');
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_JOB(payloads) {
  const { payload, refreshList } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, status } = yield call(Job.updateJob, payload);

    if (success) {
      if (refreshList) yield put({ type: actions.INVOKE_LIST_JOB });
      else {
        yield put({
          type: actions.INVOKE_GET_JOB,
          payload: { id: payload?.id },
        });
        message.success('Job updated successfully.');
      }
    } else {
      yield handleApiError(status);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* DELETE_JOB(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Job.deleteJob, payload);
    yield put({ type: actions.SET_STATE, payload: { list: [] } });
    yield put({ type: actions.INVOKE_LIST_JOB });
  } catch (error) {
    console.error(error);
    yield put({ type: actions.INVOKE_LIST_JOB });
  }
}

function* DUPLICATE_JOB(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const {
      success,
      message: resMessage,
      data,
      status,
    } = yield call(Job.duplicateJob, payload);
    if (success) {
      if (data?.id) {
        history.push({
          pathname: routes.JOB_SETUP(data?.id),
        });
      }
      yield put({ type: actions.INVOKE_LIST_JOB });
      message.success(resMessage);
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* ADD_JOB_TEAM_MEMBER(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Job.addMembertoHiringTeam, payload);
    if (success) {
      yield put({
        type: actions.INVOKE_GET_JOB,
        payload: { id: payload?.id },
      });
      if (payload?.next) payload.next();
      message.success(resMessage);
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_JOB_TEAM_MEMBER(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { formSubmitting: true } });
  try {
    const {
      success,
      message: resMessage,
      status,
    } = yield call(Job.deleteMemberToHiringTeam, payload);
    if (success) {
      yield put({
        type: actions.INVOKE_GET_JOB,
        payload: { id: payload?.id },
      });
      message.success(resMessage);
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* CREATE_JOB_SHORT_LINK(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { shortLinkLoading: true } });
  try {
    const {
      success,
      message: resMessage,
      data,
      status,
    } = yield call(ShortLink.createShortLink, payload);
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          jobShortLink: data?.link,
        },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { shortLinkLoading: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_JOB, CREATE_JOB),
    takeEvery(actions.INVOKE_DUPLICATE_JOB, DUPLICATE_JOB),
    takeEvery(actions.INVOKE_LIST_JOB, LIST_JOB),
    takeEvery(actions.INVOKE_GET_JOB, GET_JOB),
    takeEvery(actions.INVOKE_UPDATE_JOB, UPDATE_JOB),
    takeEvery(actions.INVOKE_DELETE_JOB, DELETE_JOB),
    takeEvery(actions.INVOKE_ADD_JOB_TEAM_MEMBER, ADD_JOB_TEAM_MEMBER),
    takeEvery(actions.INVOKE_DELETE_JOB_TEAM_MEMBER, DELETE_JOB_TEAM_MEMBER),
    takeEvery(actions.INVOKE_CREATE_JOB_SHORT_LINK, CREATE_JOB_SHORT_LINK),
  ]);
}
