export default {
  SET_STATE: 'interviews/SET_STATE',
  SET_ADD_ALL_INTERVIEW_LIST: 'interviews/SET_ADD_ALL_INTERVIEW_LIST',

  INVOKE_CREATE_INTERVIEW: 'interviews/INVOKE_CREATE_INTERVIEW',
  INVOKE_LIST_INTERVIEW: 'interviews/INVOKE_LIST_INTERVIEW',
  INVOKE_LIST_INTERVIEW_BY_CANDIDATE_ID:
    'interviews/INVOKE_LIST_INTERVIEW_BY_CANDIDATE_ID',
  INVOKE_GET_INTERVIEW: 'interviews/INVOKE_GET_INTERVIEW',
  INVOKE_UPDATE_INTERVIEW: 'interviews/INVOKE_UPDATE_INTERVIEW',
  INVOKE_DELETE_INTERVIEW: 'interviews/INVOKE_DELETE_INTERVIEW',
};
