import { all } from 'redux-saga/effects';

import user from 'redux/user/sagas';
import forms from 'redux/forms/sagas';
import users from 'redux/users/sagas';
import events from 'redux/events/sagas';
import interviews from 'redux/interviews/sagas';
import decides from 'redux/decides/sagas';
import offers from 'redux/offers/sagas';
import hireds from 'redux/hireds/sagas';
import archiveds from 'redux/archiveds/sagas';
import applicants from 'redux/applicants/sagas';
import jobs from 'redux/jobs/sagas';
import screens from 'redux/screens/sagas';
import jobApplicationForms from 'redux/jobApplicationForm/sagas';
import comments from 'redux/comments/sagas';
import reviews from 'redux/reviews/sagas';
import portals from 'redux/portals/sagas';
import template from 'redux/template/sagas';
import search from 'redux/search/sagas';
import stages from 'redux/stages/sagas';
import workflows from 'redux/workflows/sagas';
import category from 'redux/category/sagas';
import domains from 'redux/domains/sagas';
import messages from 'redux/messages/sagas';
import talentCommunity from 'redux/talentCommunity/sagas';
import team from 'redux/team/sagas';

export default function* rootSaga() {
  yield all([
    user(),
    users(),
    forms(),
    events(),
    screens(),
    interviews(),
    decides(),
    offers(),
    hireds(),
    archiveds(),
    applicants(),
    jobs(),
    jobApplicationForms(),
    comments(),
    reviews(),
    portals(),
    template(),
    search(),
    stages(),
    workflows(),
    category(),
    domains(),
    messages(),
    talentCommunity(),
    team(),
  ]);
}
