import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, Input, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from 'redux/applicants/actions';
import { debounce } from 'lodash';
import { routes } from 'helpers/routes';
import { history } from 'helpers/history';
import { APPLICANT_PAGE_LIMIT } from 'helpers/constants';
import { useQuery } from 'hooks/useQuery';

const tagStyleP = {
  maxWidth: '100px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
};

const tagStyle = {
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(24,144,255, 0.8)',
  color: 'white',
  padding: '4px 8px',
  borderRadius: '4px',
  marginRight: '8px',
  fontSize: '12px',
};

const SearchBar = ({
  currentStage,
  stages,
  searchApplicants,
  searchList,
  searchListLoading,
  currentJob,
}) => {
  const queryParams = useQuery();

  const [stageTag, setStageTag] = useState({});
  const [jobTag, setJobTag] = useState({});
  const [showTags, setShowTags] = useState(false);
  const [mouseDownOnTags, setMouseDownOnTags] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (queryParams.searchTerm) {
      setInputValue(queryParams.searchTerm);
    }
  }, [queryParams.searchTerm]);

  useEffect(() => {
    return () => {
      setJobTag({});
      setStageTag({});
    };
  }, [currentJob, currentStage]);

  useEffect(() => {
    if (currentJob && Object.values(currentJob).length > 0) {
      setJobTag({
        value: `Job: ${currentJob.title}`,
        id: currentJob.id,
      });
    }
    if (currentStage) {
      const stageName = stages.find(
        (element) => element.id === currentStage
      )?.name;
      if (stageName)
        setStageTag({
          value: `Stage: ${
            stageName.charAt(0).toUpperCase() + stageName.slice(1).toLowerCase()
          }`,
          id: currentStage,
        });
    }
  }, [currentJob, currentStage, stages]);

  useEffect(() => {
    const search = debounce(() => {
      searchApplicants(inputValue, jobTag.id, stageTag.id);
    }, 500);

    search();

    return () => {
      search.cancel();
    };
  }, [inputValue, jobTag, stageTag, searchApplicants]);

  const handleSearch = () => {
    history.push(`/search?searchTerm=${inputValue}`);
  };

  return (
    <div
      style={{ width: '50%' }}
      onFocus={() => setShowTags(true)}
      onBlur={() => {
        if (!mouseDownOnTags) {
          setShowTags(false);
        }
      }}
    >
      <AutoComplete
        options={searchList.map((c) => ({
          value: c.id,
          label: (
            <Link
              to={routes.JOB_APPLICANT_OVERVIEW(c.job.id, c.stage.id, c.id)}
              className="text-secondary d-flex align-items-center justify-content-between w-100"
              style={{ flexWrap: 'wrap' }}
            >
              <div>
                <span>{c.name}</span>
                <br />
                <span>{c.email}</span>
              </div>
              <div className="d-flex align-items-center">
                <Tag color="red">{c.stage.name.toUpperCase()}</Tag>
              </div>
            </Link>
          ),
        }))}
        style={{ width: '100%' }}
        loading={searchListLoading}
      >
        <Input
          size="large"
          placeholder="Search candidates"
          allowClear
          prefix={
            showTags && (
              <>
                {jobTag?.value && (
                  <Tag
                    className="flex justify-center"
                    key={jobTag.id}
                    closable
                    style={tagStyle}
                    onClose={() => {
                      setJobTag({});
                    }}
                    onMouseDown={() => setMouseDownOnTags(true)}
                    onMouseUp={() => setMouseDownOnTags(false)}
                    color="white"
                  >
                    <p className="m-0" style={tagStyleP}>
                      {jobTag.value}
                    </p>
                  </Tag>
                )}
                {stageTag?.value && (
                  <Tag
                    key={stageTag.id}
                    style={tagStyle}
                    closable
                    onClose={() => setStageTag({})}
                    onMouseDown={() => setMouseDownOnTags(true)}
                    onMouseUp={() => setMouseDownOnTags(false)}
                    color="white"
                  >
                    <p className="m-0" style={tagStyleP}>
                      {stageTag.value}
                    </p>
                  </Tag>
                )}
              </>
            )
          }
          suffix={
            <Button
              type="primary"
              onClick={handleSearch}
              style={{ height: '100%' }}
            >
              <i className="bi bi-search me-2" />
              Search
            </Button>
          }
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </AutoComplete>
    </div>
  );
};

const mapStateToProps = ({ jobs, applicants }) => {
  return {
    currentJob: jobs.current,
    currentStage: applicants.currentStage,
    searchList: applicants.searchList,
    searchListLoading: applicants.searchListLoading,
    stages: jobs.jobListStages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchApplicants: (
      searchTerm,
      jobId,
      stageId,
      limit = APPLICANT_PAGE_LIMIT
    ) =>
      dispatch({
        type: actions.INVOKE_SEARCH_CANDIDATES,
        payload: {
          jobId,
          currentStage: stageId,
          limit,
          searchTerm,
        },
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
