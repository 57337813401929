import React, { useEffect } from 'react';
import { Button, Dropdown, Layout, Typography, Modal } from 'antd';
import { routes } from 'helpers/routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from 'redux/user/actions';
import Avatar from 'components/Avatar';
import getMediaUrl from 'helpers/getMediaUrl';
import store from 'store';
import { history } from 'helpers/history';
import SearchBar from 'components/SearchBar';

const { Header } = Layout;
const { Title } = Typography;

let modalDisplayedRef = false;

const MainHeader = ({
  fetchMyOrganization,
  loadCurrentUser,
  logout,
  organization,
  user,
}) => {
  const items = [
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ACCOUNT);
          }}
        >
          Account settings
        </button>
      ),
      key: 'account-settings',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ORGANIZATION_MANAGE);
          }}
        >
          Your organization
        </button>
      ),
      key: 'your-organization',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.TALENT_COMMUNITY);
          }}
        >
          Talent Community
        </button>
      ),
      key: 'talent-community',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ALL_APPLICANTS);
          }}
        >
          All Candidates
        </button>
      ),
      key: 'all-applicants',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ALL_MESSAGES);
          }}
        >
          All Messages
        </button>
      ),
      key: 'all-messages',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            history.push(routes.ALL_INTERVIEWS);
          }}
        >
          All Interviews
        </button>
      ),
      key: 'all-interviews',
    },
    {
      label: (
        <button
          type="button"
          className="border-0 bg-transparent w-100 text-start"
          onClick={() => {
            logout();
          }}
        >
          Log Out
        </button>
      ),
      key: 'log-out',
    },
  ];

  useEffect(() => {
    const isLoggedIn = store.get('token');
    if (isLoggedIn && (!organization?.id || !user?.id)) {
      fetchMyOrganization();
      loadCurrentUser();
    }
  }, [organization?.id, user?.id]);

  useEffect(() => {
    if (user && user.isRandomPassword && !modalDisplayedRef) {
      modalDisplayedRef = true;
      Modal.info({
        title: 'Secure your account',
        content: (
          <div>
            <p>
              Your password is not secure. Please change it to ensure the
              security of your account.
            </p>
          </div>
        ),
        onOk: () => {
          history.push(routes.ACCOUNT_CHANGE_PASSWORD);
          Modal.destroyAll();
        },
        okText: 'Change password',
        closable: true,
      });
    }
  }, [user]);

  return (
    <Header className="d-flex justify-content-between align-items-center bg-light px-3">
      <Link to={routes.DASHBOARD}>
        <Title
          level={5}
          style={{ margin: '0' }}
          className="d-flex justify-content-center align-items-center gap-2"
        >
          <Avatar
            name={organization?.name}
            avatarUrl={getMediaUrl(organization?.logo?.filename)}
          />
          {organization?.name}
        </Title>
      </Link>
      <SearchBar />

      <div className="d-flex justify-content-center align-items-center">
        {/* NOTIFICATION UI Hidden */}
        {/* <Button
          key="1"
          type="text"
          onClick={() => {
            history.push(routes.NOTIFICATION);
          }}
        >
          <i className="bi bi-bell" />
        </Button> */}
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button
            type="text"
            className="bg-transparent border-0 p-0 d-flex justify-content-center align-items-center gap-1"
          >
            <Avatar
              name={user?.name}
              avatarUrl={getMediaUrl(user?.avatar?.filename)}
            />
            <i className="bi bi-caret-down" />
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    organization: user.organization,
    user: user.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch({
        type: actions.LOGOUT,
      }),
    fetchMyOrganization: () =>
      dispatch({
        type: actions.FETCH_MY_ORGANIZATION,
      }),

    loadCurrentUser: () => dispatch({ type: actions.LOAD_CURRENT_ACCOUNT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
