import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';

const CreateJobPostModal = ({
  modalVisible = false,
  setModalVisible = () => {},
  onSubmit = () => [],
  onWorkflowSearch = () => {},
  workflowList = [],
}) => {
  const [searchWorkflow, setSearchWorkflow] = useState('');

  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const handleSearch = (value) => {
    setSearchWorkflow(value);
    onWorkflowSearch(value);
  };

  return (
    <Modal
      title="Create new job"
      centered
      open={modalVisible}
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
      closable={false}
      bodyStyle={{ padding: '5px 24px 24px 24px' }}
      footer={null}
      getContainer={document.getElementById('root')}
    >
      <Form layout="vertical" onFinish={(values) => onSubmit(values)}>
        <p className="mb-3">
          Enter the name of the job position you&apos;re hiring for. Once
          created, you&apos;ll be taken to its setup screen where you can
          configure it.
        </p>
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Job title is required',
            },
          ]}
        >
          <Input autoFocus placeholder="Job title" />
        </Form.Item>
        <Form.Item
          name="workflow"
          rules={[
            {
              required: true,
              message: 'Workflow is required',
            },
          ]}
        >
          <Select
            className="w-100"
            showSearch
            placeholder="Select a workflow"
            optionFilterProp="children"
            filterOption={false}
            onSearch={handleSearch}
            onChange={(value) => setSelectedWorkflow(value)}
            value={selectedWorkflow}
            required
          >
            {workflowList
              .filter((t) =>
                t.name.toLowerCase().includes(searchWorkflow.toLowerCase())
              )
              .map((t) => (
                <Select.Option key={t.id} value={t.id}>
                  {t.name || ''}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Button type="primary" className="me-3" htmlType="submit">
          Publish job
        </Button>
        <Button type="button" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateJobPostModal;
