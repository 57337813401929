import actions from './actions';

const initialState = {
  loading: false,
  isAuthenticated: false,
  roles: [],
  authLoading: false,
  error: { isError: false, message: '' },
  organization: null,
  organizationLoading: false,
  userRole: null,
  userData: null,
  teams: [],
  team: null,
  teamLoading: false,
  inviteLoading: false,
  inviteDetails: null,
  invitationExpired: false,
  calIntegration: false,
  calWebhook: false,
  calEvents: [],
  integrationLoading: true,
  inviteList: [],
  invitesTotalResults: 0,
  invitesTotalPages: 0,
  teamDetails: null,
  invitesPageNo: 1,
  plansLoading: false,
  plans: [],
};

export default function userReducer(state = initialState, action = undefined) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.CURRENT_USER:
      return { ...state, ...action.payload };
    case actions.LOGIN_FAILED:
      return { ...state, ...action.payload };
    case actions.LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case actions.SIGN_UP_FAILED:
      return { ...state, ...action.payload };
    case actions.VERIFY_OTP_FAILED:
      return { ...state, ...action.payload };
    case actions.SET_ERROR_DEFAULT:
      return { ...state, ...action.payload };
    case actions.SET_CART:
      return { ...state, ...action.payload };
    case actions.SERVICE_ADDED:
      return { ...state, ...action.payload };
    case actions.CLEAR_STATE:
      return { ...state, ...initialState };

    default:
      return state;
  }
}
