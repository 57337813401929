export const projectName = [
  'typesense',
  'mobsf',
  'docz',
  'hoppscotch',
  'chatwoot',
];

export const contentStatus = [
  'unassigned',
  'pending-assigned-to',
  'assigned-to',
  'draft',
  'tech-review-pending',
  'tech-review',
  'editorial-review-pending',
  'banner-pending',
  'editorial-review',
  'rework',
  'pre-publish',
  'publish',
  'reopen',
];

export const JobEmploymentTypeLabel = {
  fullTime: 'Full-time',
  partTime: 'Part-time',
  temporary: 'Temporary',
  contract: 'Contract',
  internship: 'Internship',
  volunteer: 'Volunteer',
  other: 'Other',
};

export const JobEmploymentType = {
  fullTime: 'fullTime',
  partTime: 'partTime',
  temporary: 'temporary',
  contract: 'contract',
  internship: 'internship',
  volunteer: 'volunteer',
  other: 'other',
};

export const JobRemoteOption = {
  noRemote: 'noRemote',
  remoteFriendly: 'remoteFriendly',
  remoteOnly: 'remoteOnly',
};

export const JobRemoteOptionLabel = {
  noRemote: 'No Remote',
  remoteFriendly: 'Remote Friendly',
  remoteOnly: 'Remote (Only)',
};

export const JobSalaryOption = {
  notShown: 'notShown',
  fixedAmount: 'fixedAmount',
  range: 'range',
};

export const JobPostSocialMediaOption = {
  default: 'default',
  custom: 'custom',
  none: 'none',
};

export const DEFAULT_FIELD_TYPES = {
  text: 'text',
  email: 'email',
  number: 'number',
  mobile: 'mobile',
  currency: 'currency',
  location: 'location',
  url: 'url',
  multipleChoice: 'multipleChoice',
  yesNo: 'yesNo',
  fileUpload: 'fileUpload',
  textArea: 'textArea',
};
export const KNOWN_FIELD_NAMES = {
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  totalExperience: 'totalExperience',
  linkedin: 'linkedin',
  resume: 'resume',
  noticePeriod: 'noticePeriod',
  currentCTC: 'currentCTC',
  expectedCTC: 'expectedCTC',
};

export const KnownFieldNameLabel = {
  [KNOWN_FIELD_NAMES.name]: 'Name',
  [KNOWN_FIELD_NAMES.mobile]: 'Mobile',
  [KNOWN_FIELD_NAMES.email]: 'Email',
  [KNOWN_FIELD_NAMES.resume]: 'Resume',
  [KNOWN_FIELD_NAMES.totalExperience]: 'Total Experience',
  [KNOWN_FIELD_NAMES.linkedin]: 'Linkedin',
  [KNOWN_FIELD_NAMES.github]: 'Github',
  [KNOWN_FIELD_NAMES.noticePeriod]: 'NoticePeriod',
  [KNOWN_FIELD_NAMES.currentCTC]: 'Current CTC',
  [KNOWN_FIELD_NAMES.expectedCTC]: 'Expected CTC',
  [KNOWN_FIELD_NAMES.location]: 'Location',
};

export const CompulsoryFields = [
  KNOWN_FIELD_NAMES.resume,
  KNOWN_FIELD_NAMES.name,
  KNOWN_FIELD_NAMES.email,
  KNOWN_FIELD_NAMES.mobile,
];

export const FIELD_CATEGORIES = {
  personalInfo: 'personalInfo',
  workInfo: 'workInfo',
  salaryInfo: 'salaryInfo',
  socialLinks: 'socialLinks',
  files: 'files',
  others: 'others',
};

export const KnownApplicantFields = {
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribbble: 'dribbble',
  websiteUrl: 'websiteUrl',
  source: 'source',
  resume: 'resume',
};

export const TextUniqueInputs = [
  KNOWN_FIELD_NAMES.name,
  KNOWN_FIELD_NAMES.location,
];
export const UrlUniqueInputs = [
  KNOWN_FIELD_NAMES.linkedin,
  KNOWN_FIELD_NAMES.github,
];

export const FileUniqueInputs = [
  KNOWN_FIELD_NAMES.fileUpload,
  KNOWN_FIELD_NAMES.resume,
];

export const APPLICANT_PAGE_LIMIT = 10;
export const ALL_APPLICANT_PAGE_LIMIT = 10;
export const JOB_PAGE_LIMIT = 10;
export const PORTAL_JOB_PAGE_LIMIT = 15;
export const REVIEWS_PAGE_LIMIT = 10;
export const TEAM_INVITE_PAGE_LIMIT = 5;

export const JOB_STATUS_TYPE = {
  active: 'active',
  inactive: 'inactive',
  archived: 'archived',
};

export const JOB_APPLICATION_SUCCESS_MESSAGE =
  'Application submitted successfully!🎉';

export const JOB_ALREADY_SUBMITTED_MESSAGE = 'Job already applied.';

export const USER_ROLES = {
  user: 'user',
  orgAdmin: 'orgAdmin',
  interviewer: 'interviewer',
  candidate: 'candidate',
};

export const USER_ROLES_LABEL = {
  [USER_ROLES.user]: 'User',
  [USER_ROLES.orgAdmin]: 'Admin',
  [USER_ROLES.interviewer]: 'Interviewer',
  [USER_ROLES.candidate]: 'Candidate',
};

export const APPLICANT_DETAIL_MENUS = {
  overview: 'overview',
  resume: 'resume',
  messages: 'messages',
  files: 'files',
  privateNotes: 'notes',
  tags: 'tags',
  interview: 'interview',
};

export const RESTRICTED_APPLICANT_MENUS = {
  [USER_ROLES.interviewer]: [
    APPLICANT_DETAIL_MENUS.messages,
    APPLICANT_DETAIL_MENUS.interview,
  ],
};

export const MESSAGE_TEMPLATE_VARIBLES = {
  candidateName: '[candidateName]',
  jobTitle: '[jobTitle]',
  orgName: '[orgName]',
  senderName: '[senderName]',
  senderEmail: '[senderEmail]',
  userTitle: '[userTitle]',
};

export const INVITE_STATUS_TYPE = {
  pending: 'pending',
  accepted: 'accepted',
  deleted: 'deleted',
};

export const KNOWN_TALENT_APPLICANT_KEYS = {
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribbble: 'dribbble',
  websiteUrl: 'websiteUrl',
  formData: 'formData',
  resume: 'resume',
  files: 'files',
  receiveMarketingEmails: 'receiveMarketingEmails',
};

export const EXTERNAL_APPLICANT_FIELDS = {
  resume: 'resume',
  source: 'source',
  receiveMarketingEmails: 'receiveMarketingEmails',
  name: 'name',
  email: 'email',
  mobile: 'mobile',
  location: 'location',
  linkedin: 'linkedin',
  facebook: 'facebook',
  twitter: 'twitter',
  github: 'github',
  dribbble: 'dribbble',
  websiteUrl: 'websiteUrl',
  expectedCTC: 'expectedCTC',
  currentCTC: 'currentCTC',
  noticePeriod: 'noticePeriod',
  totalExperience: 'totalExperience',
};

export const SUBSCRIPTION_PLANS = {
  free: 'free',
  plan1: 'prime',
  plan2: 'aiPrime',
};

export const AIWorkerTypes = {
  screeningRecruiter: 'screeningRecruiter',
  hrRecruiter: 'hrRecruiter',
  communicationRecruiter: 'communicationRecruiter',
  onboardingSpecialist: 'onboardingSpecialist',
};

export const AIWorkerTypesLabel = {
  screeningRecruiter: 'Screening Recruiter',
  hrRecruiter: 'HR Recuiter',
  communicationRecruiter: 'Communication Recruiter',
  onboardingSpecialist: 'Onboarding Specialist',
};
