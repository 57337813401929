import { message } from 'antd';
import { Template } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_TEMPLATE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Template.createTemplate,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      yield put({
        type: actions.INVOKE_LIST_TEMPLATE,
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_TEMPLATE(payloads) {
  const { payload = {} } = payloads;
  const { page = 1, limit = 10, ...rest } = payload;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Template.listTemplate, {
      page,
      limit,
      ...rest,
    });
    yield put({
      type: actions.SET_STATE,
      payload: { templateList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_TEMPLATE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Template.getTemplate, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_TEMPLATE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Template.updateTemplate, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.TEMPLATE_LIST);
      yield put({
        type: actions.INVOKE_LIST_TEMPLATE,
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_TEMPLATE(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Template.deleteTemplate, payload);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
    yield put({
      type: actions.INVOKE_LIST_TEMPLATE,
    });
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
    yield put({
      type: actions.INVOKE_LIST_TEMPLATE,
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_TEMPLATE, CREATE_TEMPLATE),
    takeEvery(actions.INVOKE_LIST_TEMPLATE, LIST_TEMPLATE),
    takeEvery(actions.INVOKE_GET_TEMPLATE, GET_TEMPLATE),
    takeEvery(actions.INVOKE_UPDATE_TEMPLATE, UPDATE_TEMPLATE),
    takeEvery(actions.INVOKE_DELETE_TEMPLATE, DELETE_TEMPLATE),
  ]);
}
