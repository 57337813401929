import { message } from 'antd';
import { AIAgent, TemplateRule, Workflow } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(Workflow.createWorkflow, payload);

    if (success) {
      yield put({
        type: actions.SET_ADD_NEW_WORKFLOW,
        payload: { data },
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* LIST_WORKFLOWS(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      data,
      success,
      message: resMessage,
    } = yield call(Workflow.listWorkflows, payload);
    if (data && success) {
      yield put({
        type: actions.SET_STATE,
        payload: { workflowList: data.results, loading: false },
      });
    } else {
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Workflow.getWorkflowById, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false, currentWorkflow: data },
      });
    } else {
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Workflow.updateWorkflow, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
      history.push(routes.WORKFLOW_LIST);
      yield put({
        type: actions.INVOKE_GET_WORKFLOW,
        payload: {
          id: payload.id,
        },
      });
      yield put({
        type: actions.INVOKE_LIST_WORKFLOWS,
      });
      if (payload?.next) payload.next();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_WORKFLOW_STAGES(payloads) {
  const { payload } = payloads;

  const workflows = yield select((state) => state.workflows);
  const currentWorkflow = workflows?.currentWorkflow;
  let stages = [];
  if (currentWorkflow?.stages) {
    const archivedStages = [];
    const nonArchivedstages = [];
    currentWorkflow?.stages?.forEach((stage) => {
      if (stage.isArchiveStage) archivedStages.push(stage.id);
      else nonArchivedstages.push(stage.id);
    });

    stages = [...nonArchivedstages, ...payload.stages, ...archivedStages];
  } else {
    stages = payload.stages;
  }
  yield put({
    type: actions.INVOKE_UPDATE_WORKFLOW,
    payload: {
      id: payload.id,
      data: {
        stages,
      },
    },
  });
}

function* UPDATE_WORKFLOW_TEMPLATE_RULES(payloads) {
  const { payload } = payloads;

  const workflows = yield select((state) => state.workflows);
  const currentWorkflow = workflows?.currentWorkflow;
  let templateRules = [];
  if (currentWorkflow?.templateRules) {
    const templateRuleIds = currentWorkflow.templateRules.map(
      (rule) => rule.id
    );
    templateRules = [...templateRuleIds, ...payload.templateRules];
  } else {
    templateRules = payload.templateRules;
  }
  yield put({
    type: actions.INVOKE_UPDATE_WORKFLOW,
    payload: {
      id: payload.id,
      data: {
        templateRules,
      },
    },
  });
}

function* UPDATE_WORKFLOW_AI_AGENTS(payloads) {
  const { payload } = payloads;

  const workflows = yield select((state) => state.workflows);
  const currentWorkflow = workflows?.currentWorkflow;
  let aiAgents = [];
  if (currentWorkflow?.aiAgents) {
    const templateRuleIds = currentWorkflow.aiAgents.map((rule) => rule.id);
    aiAgents = [...templateRuleIds, ...payload.aiAgents];
  } else {
    aiAgents = payload.aiAgents;
  }
  yield put({
    type: actions.INVOKE_UPDATE_WORKFLOW,
    payload: {
      id: payload.id,
      data: {
        aiAgents,
      },
    },
  });
}

function* DELETE_WORKFLOW(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      data,
      success,
      message: resMessage,
    } = yield call(Workflow.deleteWorkflow, payload);
    if (success && data) {
      yield put({
        type: actions.INVOKE_LIST_WORKFLOWS,
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_WORKFLOWS,
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* CREATE_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(TemplateRule.createTemplateRule, payload);

    if (success) {
      // yield put({
      //   type: actions.SET_ADD_NEW_TEMPLATE_RULE,
      //   payload: { data },
      // });
      if (data?.id) {
        yield put({
          type: actions.INVOKE_UPDATE_WORKFLOW_TEMPLATE_RULES,
          payload: {
            templateRules: [data.id],
            id: payload.workflowId,
          },
        });
        if (payload?.next) payload.next();
      }
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* UPDATE_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      TemplateRule.updateTemplateRule,
      payload
    );

    if (success) {
      yield put({
        type: actions.INVOKE_GET_WORKFLOW,
        payload: {
          id: payload?.workflowId,
        },
      });
      if (payload?.next) payload.next();
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* DELETE_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(TemplateRule.deleteTemplateRule, payload);
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_WORKFLOWS,
      payload: {
        id: payload.candidate,
      },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* LIST_WORKFLOW_TEMPLATE_RULE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(TemplateRule.listTemplateRules, payload);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          templateRulesList: data?.results,
        },
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* CREATE_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(AIAgent.createAIAgent, payload);

    if (success) {
      if (data?.id) {
        yield put({
          type: actions.INVOKE_UPDATE_WORKFLOW_AI_AGENTS,
          payload: {
            aiAgents: [data.id],
            id: payload.workflowId,
          },
        });
        if (payload?.next) payload.next();
      }
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* UPDATE_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      AIAgent.updateAIAgent,
      payload
    );

    if (success) {
      yield put({
        type: actions.INVOKE_GET_WORKFLOW,
        payload: {
          id: payload?.workflowId,
        },
      });
      if (payload?.next) payload.next();
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

function* DELETE_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(AIAgent.deleteAIAgent, payload);
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_WORKFLOWS,
      payload: {
        id: payload.candidate,
      },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* LIST_WORKFLOW_AI_AGENT(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(AIAgent.listAIAgents, payload);

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          aiAgentsList: data?.results,
        },
      });
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_WORKFLOW, CREATE_WORKFLOW),
    takeEvery(actions.INVOKE_LIST_WORKFLOWS, LIST_WORKFLOWS),
    takeEvery(actions.INVOKE_GET_WORKFLOW, GET_WORKFLOW),
    takeEvery(actions.INVOKE_UPDATE_WORKFLOW, UPDATE_WORKFLOW),
    takeEvery(actions.INVOKE_UPDATE_WORKFLOW_STAGES, UPDATE_WORKFLOW_STAGES),
    takeEvery(
      actions.INVOKE_UPDATE_WORKFLOW_TEMPLATE_RULES,
      UPDATE_WORKFLOW_TEMPLATE_RULES
    ),
    takeEvery(actions.INVOKE_DELETE_WORKFLOW, DELETE_WORKFLOW),
    takeEvery(
      actions.INVOKE_CREATE_WORKFLOW_TEMPLATE_RULE,
      CREATE_WORKFLOW_TEMPLATE_RULE
    ),
    takeEvery(
      actions.INVOKE_UPDATE_WORKFLOW_TEMPLATE_RULE,
      UPDATE_WORKFLOW_TEMPLATE_RULE
    ),
    takeEvery(
      actions.INVOKE_DELETE_WORKFLOW_TEMPLATE_RULE,
      DELETE_WORKFLOW_TEMPLATE_RULE
    ),
    takeEvery(
      actions.INVOKE_LIST_WORKFLOW_TEMPLATE_RULE,
      LIST_WORKFLOW_TEMPLATE_RULE
    ),

    takeEvery(
      actions.INVOKE_CREATE_WORKFLOW_AI_AGENT,
      CREATE_WORKFLOW_AI_AGENT
    ),
    takeEvery(
      actions.INVOKE_UPDATE_WORKFLOW_AI_AGENT,
      UPDATE_WORKFLOW_AI_AGENT
    ),
    takeEvery(
      actions.INVOKE_DELETE_WORKFLOW_AI_AGENT,
      DELETE_WORKFLOW_AI_AGENT
    ),
    takeEvery(actions.INVOKE_LIST_WORKFLOW_AI_AGENT, LIST_WORKFLOW_AI_AGENT),

    takeEvery(
      actions.INVOKE_UPDATE_WORKFLOW_AI_AGENTS,
      UPDATE_WORKFLOW_AI_AGENTS
    ),
  ]);
}
