import { message } from 'antd';
import { Interview } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';

import { all, call, put, takeEvery } from 'redux-saga/effects';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* CREATE_INTERVIEW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Interview.createInterview,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      yield put({
        type: actions.INVOKE_LIST_INTERVIEW,
        payload: {
          id: payload.candidate,
        },
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_INTERVIEW({ payload }) {
  if (payload.page === 1)
    yield put({
      type: actions.SET_STATE,
      payload: {
        interviewListLoading: true,
        interviewList: [],
      },
    });
  else
    yield put({
      type: actions.SET_STATE,
      payload: { interviewListLoading: true },
    });
  try {
    const {
      data,
      success,
      status,
      messaged: resMessage,
    } = yield call(Interview.listInterview, payload);

    if (success) {
      if (data.page > 1) {
        yield put({
          type: actions.SET_ADD_ALL_INTERVIEW_LIST,
          payload: {
            interviewList: data.results,
            interviewListLoading: false,
            interviewListCurrentPage: data.page,
          },
        });
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            interviewList: data.results,
            interviewListTotalPages: data.totalPages,
            interviewListTotalResults: data.totalResults,
            interviewListLimit: data.limit,
            interviewListLoading: false,
            interviewListCurrentPage: data.page,
          },
        });
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { interviewListLoading: false },
    });
  }
}

function* LIST_INTERVIEW_BY_CANDIDATE_ID(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Interview.listInterview, payload);
    yield put({
      type: actions.SET_STATE,
      payload: { interviewListByCandidate: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_INTERVIEW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Interview.getInterview, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_INTERVIEW(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Interview.updateInterview, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.INTERVIEW_LIST);
      yield put({
        type: actions.INVOKE_LIST_INTERVIEW,
        payload: {
          id: payload.candidate,
        },
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_INTERVIEW(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { success } = yield call(Interview.deleteInterview, payload);
    if (success) {
      message.success('Interview cancelled');
      yield put({
        type: actions.INVOKE_LIST_INTERVIEW,
        payload: {
          id: payload.candidate,
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_INTERVIEW,
      payload: {
        id: payload.candidate,
      },
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_INTERVIEW, CREATE_INTERVIEW),
    takeEvery(actions.INVOKE_LIST_INTERVIEW, LIST_INTERVIEW),
    takeEvery(
      actions.INVOKE_LIST_INTERVIEW_BY_CANDIDATE_ID,
      LIST_INTERVIEW_BY_CANDIDATE_ID
    ),
    takeEvery(actions.INVOKE_GET_INTERVIEW, GET_INTERVIEW),
    takeEvery(actions.INVOKE_UPDATE_INTERVIEW, UPDATE_INTERVIEW),
    takeEvery(actions.INVOKE_DELETE_INTERVIEW, DELETE_INTERVIEW),
  ]);
}
