import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from 'redux/user/reducers';
import forms from 'redux/forms/reducers';
import users from 'redux/users/reducers';
import events from 'redux/events/reducers';
import interviews from 'redux/interviews/reducers';
import decides from 'redux/decides/reducers';
import offers from 'redux/offers/reducers';
import hireds from 'redux/hireds/reducers';
import archiveds from 'redux/archiveds/reducers';
import applicants from 'redux/applicants/reducers';
import jobs from 'redux/jobs/reducers';
import jobApplicationForms from 'redux/jobApplicationForm/reducers';
import comments from 'redux/comments/reducers';
import reviews from 'redux/reviews/reducers';
import portals from 'redux/portals/reducers';
import template from 'redux/template/reducers';

import screens from 'redux/screens/reducers';
import search from 'redux/search/reducers';
import stages from 'redux/stages/reducers';
import workflows from 'redux/workflows/reducers';
import category from 'redux/category/reducers';
import domains from 'redux/domains/reducers';
import messages from 'redux/messages/reducers';
import talentCommunity from 'redux/talentCommunity/reducers';
import team from 'redux/team/reducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    users,
    forms,
    events,
    interviews,
    screens,
    decides,
    offers,
    hireds,
    archiveds,
    applicants,
    jobs,
    jobApplicationForms,
    comments,
    reviews,
    portals,
    template,
    search,
    stages,
    workflows,
    category,
    domains,
    messages,
    talentCommunity,
    team,
  });
