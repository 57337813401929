import { message } from 'antd';
import { Category } from 'apis/APISDK';
import { history } from 'helpers/history';
import { routes } from 'helpers/routes';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* CREATE_CATEGORY(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const { success, message: resMessage } = yield call(
      Category.createCategory,
      payload
    );

    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      yield put({
        type: actions.INVOKE_LIST_CATEGORY,
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* LIST_CATEGORY(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(Category.listCategories, payload);
    yield put({
      type: actions.SET_STATE,
      payload: { categoryList: data.results, loading: false },
    });
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_CATEGORY(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const { success, data } = yield call(Category.getCategory, {
      id: payload.id,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false },
      });
      payload.next(data);
    } else {
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

function* UPDATE_CATEGORY(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formSubmitting: true },
  });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(Category.updateCategory, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
      history.push(routes.CATEGORY_LIST);
      yield put({
        type: actions.INVOKE_LIST_CATEGORY,
      });
    } else {
      message.error(resMessage);
      yield put({
        type: actions.SET_STATE,
        payload: { formSubmitting: false },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formSubmitting: false } });
  }
}

function* DELETE_CATEGORY(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(Category.deleteCategory, payload);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
    yield put({
      type: actions.INVOKE_LIST_CATEGORY,
    });
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
    yield put({
      type: actions.INVOKE_LIST_CATEGORY,
    });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_CREATE_CATEGORY, CREATE_CATEGORY),
    takeEvery(actions.INVOKE_LIST_CATEGORY, LIST_CATEGORY),
    takeEvery(actions.INVOKE_GET_CATEGORY, GET_CATEGORY),
    takeEvery(actions.INVOKE_UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeEvery(actions.INVOKE_DELETE_CATEGORY, DELETE_CATEGORY),
  ]);
}
