import actions from './actions';

const initialState = {
  workflowList: [],
  currentWorkflow: [],
  templateRulesList: [],
  aiAgentsList: [],
  loading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.SET_ADD_NEW_WORKFLOW:
      return {
        ...state,
        workflowList: [...state.workflowList, action.payload.data],
      };

    default:
      return state;
  }
}
