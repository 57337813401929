import { message } from 'antd';
import { FormEntries } from 'apis/APISDK';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

function* LIST_FORM_ENTRIES(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    const {
      success,
      data,
      message: resMessage,
    } = yield call(FormEntries.getFormEntries, payload);

    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formEntries: data?.results?.reverse(), loading: false },
      });
    } else {
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* GET_FORM_ENTRY(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(FormEntries.getFormEntry, {
      formEntryId: payload.formId,
    });
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { currentFormEntry: data, loading: false },
      });
      payload.next(data?.formData, data?.name);
    } else {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_FORM_ENTRY(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(FormEntries.updateFormEntry, payload);
    if (success && data) {
      payload.next();
      yield put({
        type: actions.SET_STATE,
        payload: { formEntries: [], currentFormEntry: null },
      });
      yield put({
        type: actions.INVOKE_LIST_FORM_ENTRY,
        payload: { formType: payload.formType },
      });
    } else {
      payload.next();
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
    }
  } catch (error) {
    payload.next();
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* DELETE_FORM_ENTRY(payloads) {
  const { payload } = payloads;

  yield put({ type: actions.SET_STATE, payload: { loading: true } });
  try {
    yield call(FormEntries.deleteFormEntry, payload);
    yield put({ type: actions.SET_STATE, payload: { formEntries: [] } });
    yield put({
      type: actions.INVOKE_LIST_FORM_ENTRY,
      payload: { formType: payload.formType },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actions.INVOKE_LIST_FORM_ENTRY,
      payload: { formType: payload.formType },
    });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_FORM_ENTRY, LIST_FORM_ENTRIES),
    takeEvery(actions.INVOKE_GET_FORM_ENTRY, GET_FORM_ENTRY),
    takeEvery(actions.INVOKE_UPDATE_FORM_ENTRY, UPDATE_FORM_ENTRY),
    takeEvery(actions.INVOKE_DELETE_FORM_ENTRY, DELETE_FORM_ENTRY),
  ]);
}
