import React from 'react';
import { Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import APP_NAME from 'helpers/appName';

const { Header } = Layout;
const { Title } = Typography;

const AuthLayout = ({ children }) => {
  // const history = useHistory();

  return (
    <div className="vh-100 bg-light">
      <Header className="d-flex justify-content-center align-items-center bg-light px-3 border">
        <Link to="/login">
          <Title level={5} className="m-0 ">
            {APP_NAME}
          </Title>
        </Link>
      </Header>
      {children}
    </div>
  );
};

export default AuthLayout;
