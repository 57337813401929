import { message } from 'antd';
import { Form, JobApplicationForm } from 'apis/APISDK';
import { generateDefaultFilterValues } from 'components/FilterDrawer/generateFilterFields';
import {
  all,
  call,
  delay,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import actions from './actions';
import jobActions from '../jobs/actions';

function* GET_JOB_APPLICATION_FORM(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  });
  try {
    const { success, data } = yield call(
      JobApplicationForm.getJobApplicationForm,
      { id: payload.id }
    );
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          formFields: data?.fields || [],
          formId: data?.id,
          loading: false,
          defaultFormFields: generateDefaultFilterValues(data?.fields || []),
        },
      });
      yield put({
        type: jobActions.SET_STATE,
        payload: { filter: generateDefaultFilterValues(data?.fields || []) },
      });
    } else {
      yield put({ type: actions.SET_STATE, payload: { loading: false } });
      message.error('Failed to fetch job details, please try again.');
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

function* UPDATE_JOB_APPLICATION_FORM({ payload, formId, next, isDebounce }) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true, formFields: payload.fields },
  });

  try {
    if (isDebounce) yield delay(1500);
    const { success } = yield call(
      Form.updateJobApplicationForm,
      payload,
      formId
    );

    if (success) {
      message.success('Job Form Updated');
      yield put({
        type: actions.INVOKE_GET_JOB_APPLICATION_FORM,
        payload: { id: payload.job },
      });

      if (next) next();
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  } finally {
    yield put({ type: actions.SET_STATE, payload: { loading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(
      actions.INVOKE_GET_JOB_APPLICATION_FORM,
      GET_JOB_APPLICATION_FORM
    ),
    takeLatest(
      actions.INVOKE_UPDATE_JOB_APPLICATION_FORM,
      UPDATE_JOB_APPLICATION_FORM
    ),
  ]);
}
