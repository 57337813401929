export default {
  SET_STATE: 'SET_STATE',
  CLEAR_STATE: 'CLEAR_STATE',

  LOGIN: 'user/LOGIN',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOGIN_FAILED: 'user/LOGIN_FAILED',

  SIGN_UP: 'user/SIGN_UP',
  SIGN_UP_SUCCESS: 'user/SIGN_UP_SUCCESS',
  SIGN_UP_FAILED: 'user/SIGN_UP_FAILED',

  SIGNUP_CREATE: 'user/SIGNUP_CREATE',
  SIGNUP_CREATE_SUCCESS: 'user/SIGNUP_CREATE_SUCCESS',
  SIGNUP_CREATE_FAILED: 'user/SIGNUP_CREATE_FAILED',

  GET_SIGNUP_DETAILS: 'user/GET_SIGNUP_DETAILS',

  CREATE_ORGANIZATION: 'user/CREATE_ORGANIZATION',
  UPDATE_ORGANIZATION: 'user/UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_SLUG: 'user/UPDATE_ORGANIZATION_SLUG',
  FETCH_MY_ORGANIZATION: 'user/FETCH_MY_ORGANIZATION',

  LIST_TEAMS: 'user/LIST_TEAMS',
  INVOKE_GET_TEAM: 'user/INVOKE_GET_TEAM',
  INVOKE_DELETE_TEAM_MEMBER: 'user/INVOKE_DELETE_TEAM_MEMBER',

  INVITE_TEAM_MEMBER: 'user/INVITE_TEAM_MEMBER',
  GET_INVITE_DETAILS: 'user/GET_INVITE_DETAILS',
  INVOKE_LIST_INVITES: 'user/INVOKE_LIST_INVITES',
  INVOKE_DELETE_INVITE: 'user/INVOKE_DELETE_INVITE',
  INVOKE_RESEND_INVITE: 'user/INVOKE_RESEND_INVITE',

  RESEND_VERIFY_EMAIL: 'user/RESEND_VERIFY_EMAIL',
  RESEND_VERIFY_EMAIL_FAILED: 'user/RESEND_VERIFY_EMAIL_FAILED',

  VERIFY_EMAIL: 'user/VERIFY_EMAIL',
  VERIFY_EMAIL_FAILED: 'user/VERIFY_EMAIL_FAILED',

  LOGOUT: 'user/LOGOUT',

  SET_ERROR_DEFAULT: 'user/SET_ERROR_DEFAULT',

  CURRENT_USER: 'user/CURRENT_USER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  INVOKE_CHANGE_PASSWORD: 'user/INVOKE_CHANGE_PASSWORD',

  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  FORGOT_PASSWORD_FAILED: 'usesr/FORGOT_PASSWORD_FAILED',
  FORGOT_PASSWORD_SUCCESS: 'usesr/FORGOT_PASSWORD_SUCCESS',

  RESET_PASSWORD: 'user/RESET_PASSWORD',
  RESET_PASSWORD_FAILED: 'usesr/RESET_PASSWORD_FAILED',
  RESET_PASSWORD_SUCCESS: 'usesr/RESET_PASSWORD_SUCCESS',

  CONTACT_MEMBER: 'user/CONTACT_MEMBER',
  SYNC_LOCAL_CART: 'user/SYNC_LOCAL_CART',
  SET_CART: 'user/SET_CART',
  ADD_SERVICE_TO_CART: 'user/ADD_SERVICE_TO_CART',
  SERVICE_ADDED: 'user/SERVICE_ADDED',

  INVOKE_CLEAR_PROFILE: 'user/INVOKE_CLEAR_PROFILE',

  UPDATE_USER_DETAILS: 'user/UPDATE_USER_DETAILS',

  CREATE_CAL_INTEGRATION: 'user/CREATE_CAL_INTEGRATION',
  CHECK_CAL_INTEGRATION: 'user/CHECK_CAL_INTEGRATION',
  FETCH_CAL_EVENTS: 'user/FETCH_CAL_EVENTS',
  CREATE_CAL_EVENT: 'user/CREATE_CAL_EVENT',
  ADD_CAL_WEBHOOK: 'user/ADD_CAL_WEBHOOK',
  SYNC_CAL_BOOKINGS: 'user/SYNC_CAL_BOOKINGS',

  FETCH_PLANS: 'user/FETCH_PLANS',
};
